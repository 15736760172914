<template>
  <div>
    <el-dialog
      title="Export"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="key === 'date'"
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="exporting"
            @click="getExportData"
            :disabled="!form.format || !form.fields.length"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "OfaHistoryExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      fields: {
        date: "Date",
        meals: "Meals",
        juices: "Juices",
        lastMealPacked: "Last Meal Packed",
        lastMealDispatched: "Last Meal Dispatched",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["date"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["date"];
      this.form.format = "";
      this.exporting = false;
      this.shouldShow = false;
    },

    getExportData() {
      this.loading = true;
      this.formatExportData(this.data);
    },

    formatExportData(data) {
      let from = this.formatDate(
        this.data[this.data.length - 1].day,
        "do m, y"
      );
      let to = this.formatDate(this.data[0].day, "do m, y");

      let title = `Orders History - ${from} - ${to}`;
      let headers = {
        sn: "S/N",
      };
      let extra_headers = {};
      let combocheckData = [];
      this.form.fields.forEach(
        (key) => (extra_headers[key] = this.fields[key])
      );
      headers = { ...headers, ...extra_headers };
      combocheckData = data.map((day, index) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "sn":
              export_object[key] = index + 1;
              break;
            case "date":
              export_object[key] = this.formatDate(day.day, "ddd do, m y");
              break;
            case "meals":
              export_object[key] = day.number_of_meals;
              break;
            case "juices":
              export_object[key] = day.number_of_juices;
              break;
            case "lastMealPacked":
              export_object[key] = this.formatTime(day.last_meal_packed_at);
              break;
            case "lastMealSealed":
              export_object[key] = this.formatTime(day.last_meal_dispatched_at);
              break;
            default:
              break;
          }
        });
        return export_object;
      });
      this.exportData = {
        title,
        headers,
        data: combocheckData,
        fileName: "Orders History",
        titleMargin: 34,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.downloadComboCsv,
.downloadComboXls {
  position: absolute;
  opacity: 0;
}
</style>
