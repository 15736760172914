<template>
  <div>
    <eden-page-header :title="'Order Fulfillment'" :subtitle="'History'" />
    <eden-table-actions
      :title="'Order Fulfillment History'"
      :show-search="false"
    >
      <template slot="actions">
        <eden-period-filter @clear="setPageData" @confirm="filterOrders" />
        <el-button
          type="plain"
          icon="eden-icon-upload"
          class="ml-10"
          :disabled="loading || !pageData.length"
          @click="exporting = true"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <p v-if="loading">Loading</p>

    <template v-else>
      <el-table :data="pageData">
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Date</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatDate(scope.row.day, "do m, y") }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="220">
          <template slot="header">
            <div class="table--header">
              <span>Number of Food from Eden</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm"> {{ scope.row.number_of_meals }}</span>
          </template>
        </el-table-column>
        <el-table-column width="220">
          <template slot="header">
            <div class="table--header">
              <span>Number of Juices from KMD</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm"> {{ scope.row.number_of_juices }}</span>
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template slot="header">
            <div class="table--header">
              <span>Last meal packed</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatTime(scope.row.last_meal_packed_at) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="180">
          <template slot="header">
            <div class="table--header">
              <span>Last meal dispatched</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              {{ formatTime(scope.row.last_meal_dispatched_at) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header"></div>
          </template>
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'order-fulfillment.date',
                params: { date: formatDate(scope.row.day, 'y-m-d') },
              }"
              class="text-underline text-primary text-cursor"
            >
              Expand details</router-link
            >
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        :from="from"
        :to="to"
        :total="total"
        :current-page.sync="page"
      />
    </template>
    <ofa-history-export :show.sync="exporting" :data="pageData" />
  </div>
</template>

<script>
import OfaHistoryExport from "@/components/Stewards/OFA/OfaHistoryExport";
import * as actions from "@/store/action-types";
import ofa from "@/requests/stewards/ofa";

export default {
  name: "OrdersFulfillmentHistory",
  components: { OfaHistoryExport },
  data() {
    return {
      pageData: [],
      loading: false,
      page: 1,
      showPagination: true,
      exporting: false,
    };
  },
  computed: {
    orders() {
      return this.$store.getters.ofa_history;
    },
    from() {
      return this.orders.pages[this.page].from;
    },
    to() {
      return this.orders.pages[this.page].to;
    },
    total() {
      return this.orders.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      const length = this.showPagination ? total || 0 : result;

      return `${length} Orders${length > 1 ? "s" : ""}`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.orders.pages[this.page];
      if (!pageFetched) {
        this.getOrderHistory();
      }
    },
  },
  created() {
    this.getOrderHistory();
  },
  methods: {
    getOrderHistory() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_OFA_HISTORY, {
          page: this.page,
        })
        .then(() => {
          this.setPageData();
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    filterOrders(daterange) {
      const { from, to } = daterange;
      this.loading = true;
      ofa
        .filterDate(from, to)
        .then((response) => {
          if (response.data.status) {
            if (response.data.status) {
              this.pageData = response.data.data;
              this.loading = false;
            } else {
              this.loading = false;
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    setPageData() {
      this.pageData = JSON.parse(
        JSON.stringify(this.orders.pages[this.page].data),
      );
      this.showPagination = true;
      this.loading = false;
    },
  },
};
</script>
